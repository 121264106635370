import React from "react";
import { Col, Label, Row, Input, Container } from "reactstrap";
import "../JobLocation.css";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import { updateJob, updateWorkMode } from "store/jobs/actions";
import TimeZone from "./timezone";
import full_remote from "../../../../assets/images/zepul/full_remote.png";
import partially_remote from "../../../../assets/images/zepul/partially_remote.png";
import onSite from "../../../../assets/images/zepul/on_site.png";
import { PrimaryAutoComplete,  BlackTypography,
  PrimaryNormalInputs,
  autocompleteProps, } from "assets/Mui/globalTheme";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import parse from "autosuggest-highlight/parse";
import { debounce } from "@mui/material/utils";
import { Box, Grid } from "@mui/material";
const GOOGLE_MAPS_API_KEY = "AIzaSyCnMZIj6f1g_ZhhELgVRia8_22UT43glsM";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };


const JobLocation = ({ global_w_md, location, updateJob }) => {
  const data = [
    {
      img: full_remote,
      name: "Fully Remote",
      work_mode: "Fully_remote",
    },
    {
      img: partially_remote,
      name: "Partially Remote",
      work_mode: "Partially_remote",
    },
    {
      img: onSite,
      name: "On-site",
      work_mode: "On_site",
    },
  ];
  const [value, setValue] = React.useState(location);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <>
      <Row className=" mt-4" style={{ alignItems: "center" }}>
        <Col xs={12} style={{ margin: "auto" }}>
          <h5>
            Select Work Mode<span className="red py-2 px-2">*</span>
          </h5>
        </Col>
        {data.map(({ img, name, work_mode }, index) => {
          return (
            <Col lg={3} md={4} xs={12} sm={12} className="py-2" key={index}>
              <div
                style={{
                  overflow: "hidden",
                  transition: "0.4s ease-in-out",
                  borderWidth: "1px",
                }}
                className={`work_modes_selection ${
                  global_w_md === work_mode ? "selected_work_mode" : " "
                }`}
                onClick={() => {
                  updateJob({ work_mode: work_mode });
                }}
              >
                <img src={img} className="max_img_container" />
                <Label>{name}</Label>
              </div>
            </Col>
          );
        })}
      </Row>
      <div className="mt-3">
        {global_w_md === "Fully_remote" ? <TimeZone /> : ""}
        {global_w_md === "Partially_remote" || global_w_md === "On_site" ? (
          <>
            <Col xs={12} style={{ margin: "auto" }}>
              <h3 style={{ padding: "5px" }}>Enter Location</h3>
            </Col>
            <PrimaryAutoComplete
              componentsProps={autocompleteProps}
              id="google-map-demo"
              placeholder="Add a location"
              getOptionLabel={option =>
                typeof option === "string" ? option : option.description
              }
              filterOptions={x => x}
              options={options}
              autoComplete
              includeInputInList
              filterSelectedOptions
              value={value}
              noOptionsText="No locations"
              onChange={(_, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                setValue(newValue);
                updateJob({ location: newValue.description });
              }}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderInput={params => (
                <PrimaryNormalInputs
                  ref={params.InputProps.ref}
                  placeholder="add a location"
                  sx={{ marginTop: "8px" }}
                  {...params}
                  // error={errors[updatingContent] ? true : false}
                  // value={value}
                  // onChange={event => {
                  // setValue(event.target.value);
                  // }}
                  label="Add a location"
                  fullWidth
                />
              )}
              renderOption={(props, option) => {
                const matches =
                  option?.structured_formatting?.main_text_matched_substrings ||
                  [];
                const parts = parse(
                  option?.structured_formatting?.main_text,
                  matches?.map(match => [
                    match.offset,
                    match.offset + match.length,
                  ])
                );
                return (
                  <li {...props}>
                    <Grid container alignItems="center">
                      <Grid item sx={{ display: "flex", width: 44 }}>
                        <FeatherIcon icon="map-pin" size="16" />
                      </Grid>
                      <Grid
                        item
                        sx={{
                          width: "calc(100% - 44px)",
                          wordWrap: "break-word",
                        }}
                      >
                        {parts.map((part, index) => (
                          <Box
                            key={index}
                            component="span"
                            sx={{
                              fontWeight: part.highlight ? "bold" : "regular",
                            }}
                          >
                            {part.text}
                          </Box>
                        ))}

                        <BlackTypography variant="h5">
                          {option.structured_formatting?.secondary_text}
                        </BlackTypography>
                      </Grid>
                    </Grid>
                  </li>
                );
              }}
            />
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
};
const mapStateToProps = ({ Jobs }) => ({
  global_w_md: Jobs.work_mode,
  location: Jobs.location,
});

const mapDispatchToProps = dispatch => ({
  updateWorkMode: e => dispatch(updateWorkMode(e)),
  updateJob: e => dispatch(updateJob(e)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobLocation);
